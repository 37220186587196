import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

import Cover from '../components/Cover'
import VideoBox from '../components/VideoBox'
import CTA from '../components/Cta'

import coverImage from '../images/abusivismo-in-finanza.jpg'

import posterAbusivismo from '../images/poster-abusivismo-in-finanza.png'

const Impulsivita = () => (
  <Layout>
    <SEO title="L'abusivismo in finanza" />
    <Cover
      title="L'abusivismo in finanza"
      color="white"
      bg={coverImage}
      icon=""
      link="#video"
    >
      <p>
        Per muoversi con sicurezza nel mare magnum dell’offerta finanziaria
        online, evitando le insidie di soggetti che operano abusivamente, senza
        autorizzazioni e senza rispetto delle normative vigenti, l’opzione
        consigliata è quella di rivolgersi a un consulente regolarmente iscritto
        all’albo.
      </p>
    </Cover>
    <VideoBox ytID="cwa9UTpyPGA" poster={posterAbusivismo} />
    <CTA />
  </Layout>
)

export default Impulsivita
